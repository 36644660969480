import React, { Fragment } from "react";

const howto = (props) => {
  return (
    <Fragment>
      <section id="click-section-3" className="bg__gray-3 overflow-hidden">
        <div className="my-5">
          <div className="position-relative">
            <img src="./../images/Ellipse 157.png" className="path-9" alt="" />
            <div className="container px-md-5">
              <div className="text-center">
                <h4
                  className="bold font__size--60 text__60-1024 text__gradient-purple mb__6"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  How We Works?
                </h4>
              </div>
              <div className="row">
                <div className="col-md-6 order-md-1 text-center text-md-left order-12 my-auto">
                  <h4
                    className="bold font__size--32 text__32-1024 text__32-xs lh__5 color__darkblue"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    Data-Driven UX design for a{" "}
                    <br className="d-none d-xl-block" /> perfectly{" "}
                    <span className="text__gradient-pink">
                      Intuitive Product
                    </span>
                  </h4>

                  <div
                    className="line__benefit d-inline-block w-100 mt-5"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  ></div>
                </div>
                <div className="col-md-6 pb-4 pb-md-0 order-1 order-md-12 my-auto text-right">
                  <img
                    src="./../images/Group 18849.png"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative mt-5 mt-md-0">
            <img src="./../images/Ellipse 157.png" className="path-10" alt="" />
            <img
              src="./../images/Ellipse 154.png"
              className="path-11 d-none d-md-block"
              alt=""
            />
            <div className="container px-md-5">
              <div className="row">
                <div className="col-md-6 pb-4 pb-md-0 my-auto">
                  <img
                    src="./../images/sdaasdasd.png"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                    alt=""
                  />
                </div>
                <div className="col-md-6 text-center text-md-left my-auto">
                  <h4
                    className="bold font__size--32 text__32-1024 text__32-xs lh__5 color__darkblue"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    Development ready <br className="d-none d-xl-block" />
                    custom UI that will evoke the{" "}
                    <br className="d-none d-xl-block" />
                    <span className="text__gradient-pink">
                      Emotion of your Brand
                    </span>
                  </h4>

                  <div
                    className="line__benefit d-inline-block w-100 mt-5"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative mt-5 mt-md-5">
            <img src="./../images/Ellipse 157.png" className="path-12" alt="" />
            <div className="container px-md-5">
              <div className="row">
                <div className="col-md-6 text-center text-md-left order-md-1 order-12 my-auto">
                  <h4
                    className="bold font__size--32 text__32-1024 text__32-xs lh__5 color__darkblue"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    Scalable systems to ensure{" "}
                    <br className="d-none d-xl-block" />
                    your feedback is realized{" "}
                    <br className="d-none d-xl-block" /> through{" "}
                    <span className="text__gradient-purple">
                      Daily revisions{" "}
                    </span>
                  </h4>

                  <div
                    className="line__benefit d-inline-block w-100 mt-5"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  ></div>
                </div>
                <div className="col-md-6 order-1 order-md-12 pb-4 pb-md-0 my-auto text-right">
                  <img
                    src="./../images/Group 18913.png"
                    alt=""
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative mt-5 mt-md-5">
            <img src="./../images/Ellipse 157.png" className="path-10" alt="" />
            <img
              src="./../images/Ellipse 153.png"
              className="path-13 d-none d-md-block"
              alt=""
            />
            <div className="container px-md-5">
              <div className="row">
                <div className="col-md-6 pb-4 pb-md-0 my-auto">
                  <img
                    src="./../images/Group 18851.png"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                    alt=""
                  />
                </div>
                <div className="col-md-6 text-center text-md-left my-auto">
                  <h4
                    className="bold font__size--32 text__32-1024 text__32-xs lh__5 color__darkblue"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    Create complete UI/Ux for{" "}
                    <br className="d-none d-xl-block" />
                    Web applications, mobile app,{" "}
                    <br className="d-none d-xl-block" /> and{" "}
                    <span className="text__gradient-purple">
                      cross platform applications
                    </span>
                  </h4>

                  <div
                    className="line__benefit d-inline-block w-100 mt-5"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default howto;
