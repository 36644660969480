import React, { Component, Fragment, createRef } from "react";
import { Helmet } from "react-helmet";
import { $ } from "react-jquery-plugin";
// import $ from "jquery";
import { TweenMax, TweenLite, TimelineMax, Power1, Bounce } from "gsap";
import Parallaxify from "pure-parallaxify";
import Scrollspy from "react-scrollspy";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Footer from "./../homepage/footer";

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      select: [
        "UI Style Creation",
        "Design System",
        "UX Infra",
        "Mobile app workflows",
        "Custom software ui/ux",
        "UI/UX Animation",
        "UI/UX Development",
      ],
      budget: ["Lest than $5K", "$5-10K", "$10-30K", "More than $30K"],
      error: [],
      errorHi: [],
      percentage: 0,
      upload: false,
      validation: false,
      work: {
        need: "",
        name: "",
        email: "",
        about: "",
        file: [],
        fileName: "",
        budget: "",
        policy: false,
        nda: false,
      },
      hi: {
        name: "",
        email: "",
        message: "",
        policy: false,
      },
    };
    this.inputFile = createRef();
  }

  ShowToogleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  ClickUpload = () => {
    this.inputFile.current.click();
  };

  onChangeInput = (e) => {
    let inputField = { ...this.state.work };

    if (e.target.name == "file") {
      inputField[e.target.name] = e.target.files[0];
      inputField["fileName"] = e.target.files[0].name;
    } else if (e.target.name == "nda") {
      inputField[e.target.name] = !this.state.work.nda;
    } else if (e.target.name == "policy") {
      inputField[e.target.name] = !this.state.work.policy;
    } else {
      inputField[e.target.name] = e.target.value;
    }

    this.setState({
      work: inputField,
    });
  };

  onChangeInputHi = (e) => {
    let inputField = { ...this.state.hi };

    if (e.target.name == "policy") {
      inputField[e.target.name] = !this.state.hi.policy;
    } else {
      inputField[e.target.name] = e.target.value;
    }

    this.setState({
      hi: inputField,
    });
  };

  onSelectInput = (e, b) => {
    let inputField = { ...this.state.work };
    if (e == "need") {
      inputField["need"] = b;
    }
    if (e == "budget") {
      inputField["budget"] = b;
    }

    this.setState({
      work: inputField,
    });
  };

  uploadWork = () => {
    if (this.state.work.policy == false) {
      toast.error("You must agree to the Privacy Policy", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      this.setState({
        error: [],
      });

      const data = new FormData();
      data.append("need", this.state.work.need);
      data.append("name", this.state.work.name);
      data.append("email", this.state.work.email);
      data.append("about", this.state.work.about);
      data.append("file", this.state.work.file);
      data.append("budget", this.state.work.budget);
      data.append("policy", this.state.work.policy);
      data.append("nda", this.state.work.nda);

      axios
        .post(process.env.REACT_APP_API + `apply/hi`, data, {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.round((loaded * 100) / total);
            this.setState({
              percentage: percent,
              upload: true,
            });

            if (percent == 100) {
              this.setState({
                validation: true,
              });
            }
          },
        })
        .then(
          (res) => {
            if (res.data.success == "success") {
              toast.success(
                "Successfully sent, please wait for a reply from us.",
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );

              let inputField = { ...this.state.work };
              inputField["need"] = "";
              inputField["name"] = "";
              inputField["email"] = "";
              inputField["about"] = "";
              inputField["file"] = [];
              inputField["fileName"] = "";
              inputField["budget"] = "";

              this.setState({
                work: inputField,
                upload: false,
                percentage: 0,
                validation: false,
              });
            } else {
              toast.error("there is an error please check your input", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

              this.setState({
                error: res.data.errors,
                upload: false,
                percentage: 0,
                validation: false,
              });
            }
          },
          (err) => {
            console.log("error ", err);
          }
        );
    }
  };

  uploadHi = () => {
    if (this.state.hi.policy == false) {
      toast.error("You must agree to the Privacy Policy", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      this.setState({
        errorHi: [],
      });

      const data = new FormData();
      data.append("name", this.state.hi.name);
      data.append("email", this.state.hi.email);
      data.append("message", this.state.hi.message);
      data.append("policy", this.state.hi.policy);

      axios.post(process.env.REACT_APP_API + `apply`, data).then(
        (res) => {
          if (res.data.success == "success") {
            toast.success(
              "Successfully sent, please wait for a reply from us.",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );

            let inputField = { ...this.state.hi };

            inputField["name"] = "";
            inputField["email"] = "";
            inputField["message"] = "";

            this.setState({
              hi: inputField,
            });
          } else {
            toast.error("there is an error please check your input", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            this.setState({
              errorHi: res.data.errors,
            });
          }
        },
        (err) => {
          console.log("error ", err);
        }
      );
    }
  };

  componentDidUpdate() {
    if (this.state.menu) {
      var speed = 0.5;
      var speed2 = 0.5;

      $(".list__menu-data li").each(function (i, obj) {
        TweenMax.from(obj, (speed += 0.3), {
          top: "initial",
          left: "initial",
          bottom: "initial",
          right: "initial",
          opacity: 0,
          xPercent: 0,
          yPercent: 250,
          onComplete: function () {},
        });
      });

      $(".animateBottom").each(function (i, obj) {
        TweenMax.from(obj, (speed2 += 0.3), {
          top: "initial",
          left: "initial",
          bottom: "initial",
          right: "initial",
          opacity: 1,
          xPercent: -150,
          yPercent: 0,
          onComplete: function () {},
        });
      });
    }
  }

  componentDidMount() {
    AOS.init({
      once: true,
    });

    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $(".responsive__fixed").removeClass("show");
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });

    $(function () {
      var menu = $(".wrapper__nav-fixed"),
        pos = menu.offset();

      $(window).scroll(function () {
        if ($(this).scrollTop() > menu.height()) {
          $(".wrapper__nav-fixed").addClass("active");
        } else if ($(this).scrollTop() <= menu.height()) {
          $(".wrapper__nav-fixed").removeClass("active");
        }
      });
    });
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <Helmet>
          <title>Home</title>
        </Helmet>

        <div className="overflow-hidden">
          {this.state.upload ? (
            <div
              class="modal fade show wrapper__modal-contact active"
              tabindex="-1"
              role="dialog"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bg__transparent">
                  <div className="text-center">
                    <h1 className="bold font__size--50 text__50-1024 text__60-mm text__60-xxs mb-3">
                      {this.state.percentage}%
                    </h1>
                    <p>
                      {this.state.validation
                        ? " The upload process is in progress, do not close your browser window"
                        : "The upload process is in progress, do not close your browser window"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={
              "wrapper__navbar-menu " + (this.state.menu ? "active" : "")
            }
          >
            <div className="position-relative w-100 h-100">
              <div className="list__menu bg__white">
                <div className="text-right mb-5">
                  <div
                    className="icon__close ml-auto pointer"
                    onClick={this.ShowToogleMenu.bind(this)}
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.8906 7.40137L13.9405 12.3504L8.99146 7.40137L7.3418 9.05103L12.2908 14L7.3418 18.949L8.99146 20.5987L13.9405 15.6497L18.8906 20.5987L20.5403 18.949L15.5913 14L20.5403 9.05103L18.8906 7.40137Z"
                        fill="#1A355E"
                      />
                    </svg>
                  </div>
                </div>

                <Scrollspy
                  items={[
                    "click-section-1",
                    "click-section-2",
                    "click-section-3",
                  ]}
                  currentClassName="is-current"
                  className="list__menu-data"
                >
                  <li>
                    <NavLink
                      to="/"
                      className="font__size--36 text__36-1024 bold color__darkblue animateMenu"
                    >
                      SERVICE
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/"
                      className="font__size--36 text__36-1024 bold color__darkblue animateMenu"
                    >
                      PROJECT
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/"
                      className="font__size--36 text__36-1024 bold color__darkblue animateMenu"
                    >
                      HOW IT WORKS
                    </NavLink>
                  </li>
                </Scrollspy>

                <hr className="hr__nav-menu mb-4" />
                <div className="row">
                  <div className="col-md-6 pb-4 pb-md-0 my-auto">
                    <div className="list__sosmed d-flex justify-content-md-start justify-content-center align-items-center">
                      <a href="#!">
                        <img
                          src="./../images/Frame (3).png"
                          className="animateBottom"
                          alt=""
                        />
                      </a>
                      <a href="#!">
                        <img
                          src="./../images/Frame (2).png"
                          className="animateBottom"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 my-auto text-center text-md-right">
                    <a
                      href="#!"
                      className="semi-bold font__size--18 color__darkblue d-inline-block animateBottom"
                    >
                      hi@enverstudio.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative wrapper__head">
            <img src="./../images/Group 3492.png" className="bg" alt="" />

            <img
              src={process.env.REACT_APP_URL + "images/Group 18972.png"}
              alt=""
              className="path-14 d-none d-xl-block"
            />
            <div className="path-15 bg__white d-md-flex justify-content-center align-items-center d-none">
              <img src={process.env.REACT_APP_URL + "images/adsa.png"} alt="" />
            </div>
            <div className="path-16 bg__white d-md-flex justify-content-center align-items-center d-none">
              <img src={process.env.REACT_APP_URL + "images/dda.png"} alt="" />
            </div>

            <section className="section__head contact pt-0">
              <div className="wrapper__nav-fixed">
                <div className="container">
                  <div className="wrapper__navbar position-relative z-2 d-flex justify-content-between align-items-center">
                    <NavLink to="/">
                      <img src="./../images/Group 66.svg" alt="" />
                    </NavLink>
                    <img
                      src="./../images/Frame (1).png"
                      className="pointer"
                      onClick={this.ShowToogleMenu.bind(this)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="container position-relative z-1"
                style={{ paddingTop: "173px" }}
              >
                <div className="px-xl-5">
                  <h1 className="bold font__size--50 text__50-1024 text__60-mm text__60-xxs color__white mb-3">
                    Interested? Let’s talk!
                  </h1>
                  <p className="medium font__size--14 text__14-1024 color__white lh-2">
                    Just fill this simple form in and we will contact you
                    promptly to discuss your{" "}
                    <br className="d-none d-md-block" /> project. Hate forms?
                    Drop us a line at{" "}
                    <span className="color__softblue-2">
                      hi@enverstudio.com
                    </span>
                  </p>
                </div>
              </div>
            </section>
            <div className="position-relative">
              <div className="wrapper__bg-gray"></div>
              <section className="pt-0 section__form-contact position-relative z-3">
                <div className="container">
                  <div className="px-xl-5">
                    <div className="wrapper__form-contact px-0 bg__white">
                      <div className="navigation p__x-5">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item">
                            <a
                              class="nav-link bold font__size--14 text__14-1024 color__gray-4 active"
                              id="home-tab"
                              data-toggle="tab"
                              href="#home"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              Work With Us
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link bold font__size--14 text__14-1024 color__gray-4"
                              id="profile-tab"
                              data-toggle="tab"
                              href="#profile"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              Say hi!
                            </a>
                          </li>
                        </ul>
                      </div>
                      <hr className="my-4" />
                      <div className="wrap__form p__x-5">
                        <div class="tab-content" id="myTabContent">
                          <div
                            class="tab-pane fade show active"
                            id="home"
                            role="tabpanel"
                            aria-labelledby="home-tab"
                          >
                            <div className="mb-4">
                              <h5 className="font__sie--24 text__24-1024 medium color__gray-5 mb-3">
                                I Need
                              </h5>
                              <div className="wrapper__select-need flex-wrap d-flex align-items-center">
                                {this.state.select.map((item) => (
                                  <div className="grid">
                                    <div
                                      className={
                                        "select_need pointer " +
                                        (this.state.work.need == item
                                          ? "active"
                                          : null)
                                      } //active
                                      onClick={() =>
                                        this.onSelectInput("need", item)
                                      }
                                    >
                                      <div className=" btn-need w-100 d-inline-block">
                                        <h5 className="color__darkblue bold font__size--16 text__16-1024 text__16-md text__16-mm m-0 semi-bold lh__5 position-relative z-3">
                                          {item}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <p className="font__size--14 color__red">
                                {this.state.error.need}
                              </p>
                              <div className="mb-4">
                                <div className="row">
                                  <div className="col-md-6 mb-3">
                                    <div className="wrapper__input-contact position-relative mt-3 mb-3">
                                      <input
                                        type="text"
                                        required
                                        name="name"
                                        onChange={this.onChangeInput}
                                        value={this.state.work.name}
                                        className="normal font__size--16 text__16-1024 color__black"
                                      />
                                      <label
                                        htmlFor=""
                                        className="medium font__size--20 text__20-1024 color__gray-4"
                                      >
                                        Your name
                                      </label>
                                    </div>
                                    <p className="font__size--14 color__red">
                                      {this.state.error.name}
                                    </p>
                                  </div>
                                  <div className="col-md-6 mb-3">
                                    <div className="wrapper__input-contact position-relative mt-3 mb-3">
                                      <input
                                        type="text"
                                        required
                                        name="email"
                                        onChange={this.onChangeInput}
                                        value={this.state.work.email}
                                        className="normal font__size--16 text__16-1024 color__black"
                                      />
                                      <label
                                        htmlFor=""
                                        className="medium font__size--20 text__20-1024 color__gray-4"
                                      >
                                        Your email
                                      </label>
                                    </div>
                                    <p className="font__size--14 color__red">
                                      {this.state.error.email}
                                    </p>
                                  </div>
                                  <div className="col-md-8 mb-3 my-auto">
                                    <div className="wrapper__input-contact position-relative mt-3 mb-3">
                                      <input
                                        type="text"
                                        required
                                        name="about"
                                        onChange={this.onChangeInput}
                                        value={this.state.work.about}
                                        className="normal font__size--16 text__16-1024 color__black"
                                      />
                                      <label
                                        htmlFor=""
                                        className="medium font__size--20 text__20-1024 color__gray-4"
                                      >
                                        Tell us about your project
                                      </label>
                                    </div>
                                    <p className="font__size--14 color__red">
                                      {this.state.error.about}
                                    </p>
                                  </div>
                                  <div className="col-md-4 mb-3 my-auto position-relative">
                                    <input
                                      type="file"
                                      ref={this.inputFile}
                                      className="wrapper__upload-file-hide"
                                      name="file"
                                      onChange={this.onChangeInput}
                                    />
                                    <div
                                      className="btn__upload-file pointer mt-3 mt-md-0"
                                      onClick={this.ClickUpload}
                                    >
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img
                                          src={
                                            process.env.REACT_APP_URL +
                                            "images/dfsdf.png"
                                          }
                                          alt=""
                                        />
                                        <span className="semi-bold font__size--16 text__16-1024 text__16-md ml-3">
                                          {this.state.work.fileName
                                            ? this.state.work.fileName
                                            : "Add Attachment"}
                                        </span>
                                      </div>
                                    </div>
                                    <p className="font__size--14 color__red">
                                      {this.state.error.file}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mb-3">
                              <h5 className="font__sie--24 text__24-1024 medium color__gray-5 mb-3">
                                Budget
                              </h5>
                              <div className="wrapper__select-need flex-wrap d-flex align-items-center">
                                {this.state.budget.map((item) => (
                                  <div className="grid">
                                    <div
                                      className={
                                        "select_need pointer " +
                                        (this.state.work.budget == item
                                          ? "active"
                                          : null)
                                      } //active
                                      onClick={() =>
                                        this.onSelectInput("budget", item)
                                      }
                                    >
                                      <div className=" btn-need w-100 d-inline-block">
                                        <h5 className="color__darkblue bold font__size--16 text__16-1024 text__16-md text__16-mm m-0 semi-bold lh__5 position-relative z-3">
                                          {item}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <p className="font__size--14 color__red">
                                {this.state.error.budget}
                              </p>
                            </div>
                            <div className="mb-5">
                              <div className="d-sm-flex justify-content-start align-items-center">
                                <div className="wrapper__checkbox-contact position-relative mb-3 mb-sm-0">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <input
                                      type="checkbox"
                                      id="policy"
                                      name="policy"
                                      onClick={this.onChangeInput}
                                      defaultChecked={this.state.work.policy}
                                    />
                                    <label
                                      htmlFor="policy"
                                      className="m-0 check position-relative"
                                      onClick={this.onChangeInput}
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_URL +
                                          "images/xcz.png"
                                        }
                                        alt=""
                                      />
                                    </label>
                                    <label
                                      htmlFor="policy"
                                      onClick={this.onChangeInput}
                                      className="font__size--16 text__16-1024 normal color__gray-5 ml-3 mb-0"
                                    >
                                      {" "}
                                      I accept your Privacy Policy
                                    </label>
                                  </div>
                                </div>
                                <div className="wrapper__checkbox-contact position-relative ml-sm-4">
                                  <div className="d-flex justify-content-start align-items-center">
                                    <input
                                      type="checkbox"
                                      id="nda"
                                      name="nda"
                                      onClick={this.onChangeInput}
                                      defaultChecked={this.state.work.nda}
                                    />
                                    <label
                                      htmlFor="nda"
                                      onClick={this.onChangeInput}
                                      className="m-0 check position-relative"
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_URL +
                                          "images/xcz.png"
                                        }
                                        alt=""
                                      />
                                    </label>
                                    <label
                                      onClick={this.onChangeInput}
                                      htmlFor="nda"
                                      className="font__size--16 text__16-1024 normal color__gray-5 ml-3 mb-0"
                                    >
                                      {" "}
                                      Send me NDA
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-block pb-5">
                              <button
                                onClick={this.uploadWork}
                                className="extra-bold btn__send-request font__size--14 text__14-1024 color__white btn btn__pink shadow"
                              >
                                Send Request
                              </button>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade"
                            id="profile"
                            role="tabpanel"
                            aria-labelledby="profile-tab"
                          >
                            <div className="mb-4">
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <div className="wrapper__input-contact position-relative mt-3 mb-3">
                                    <input
                                      type="text"
                                      required
                                      name="name"
                                      onChange={this.onChangeInputHi}
                                      value={this.state.hi.name}
                                      className="normal font__size--16 text__16-1024 color__black"
                                    />
                                    <label
                                      htmlFor=""
                                      className="medium font__size--20 text__20-1024 color__gray-4"
                                    >
                                      Your name
                                    </label>
                                  </div>
                                  <p className="font__size--14 color__red">
                                    {this.state.errorHi.name}
                                  </p>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="wrapper__input-contact position-relative mt-3 mb-3">
                                    <input
                                      type="text"
                                      required
                                      name="email"
                                      onChange={this.onChangeInputHi}
                                      value={this.state.hi.email}
                                      className="normal font__size--16 text__16-1024 color__black"
                                    />
                                    <label
                                      htmlFor=""
                                      className="medium font__size--20 text__20-1024 color__gray-4"
                                    >
                                      Your email
                                    </label>
                                  </div>
                                  <p className="font__size--14 color__red">
                                    {this.state.errorHi.email}
                                  </p>
                                </div>
                                <div className="col-md-12 mb-3 my-auto">
                                  <div className="wrapper__input-contact position-relative mt-3 mb-3">
                                    <input
                                      type="text"
                                      required
                                      name="message"
                                      onChange={this.onChangeInputHi}
                                      value={this.state.hi.message}
                                      className="normal font__size--16 text__16-1024 color__black"
                                    />
                                    <label
                                      htmlFor=""
                                      className="medium font__size--20 text__20-1024 color__gray-4"
                                    >
                                      Your Message
                                    </label>
                                  </div>
                                  <p className="font__size--14 color__red">
                                    {this.state.errorHi.message}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mb-5">
                              <div className="wrapper__checkbox-contact position-relative">
                                <div className="d-flex justify-content-start align-items-center">
                                  <input
                                    type="checkbox"
                                    id="privacy__contact"
                                    name="policy"
                                    onClick={this.onChangeInputHi}
                                  />
                                  <label
                                    htmlFor="privacy__contact"
                                    className="m-0 check position-relative"
                                    onClick={this.onChangeInputHi}
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_URL +
                                        "images/xcz.png"
                                      }
                                      alt=""
                                    />
                                  </label>
                                  <label
                                    onClick={this.onChangeInputHi}
                                    htmlFor="privacy__contact"
                                    className="font__size--16 text__16-1024 normal color__gray-5 ml-3 mb-0"
                                  >
                                    {" "}
                                    I accept your Privacy Policy
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="d-block pb-5">
                              <button
                                onClick={this.uploadHi}
                                className="extra-bold btn__send-request font__size--14 text__14-1024 color__white btn btn__pink shadow"
                              >
                                Send Request
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Footer bgcolor={"bg__gray-3"} />
          </div>
        </div>
      </Fragment>
    );
  }
}
