import React, { Fragment, useMemo } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Homepage from "./homepage/index";
import Contact from "./contact/index";
import Dashboard from "./dashboard/contact";
import DashWork from "./dashboard/work";
import Login from "./auth/login";

const Index = (props) => {
  const login = localStorage.getItem("loggedIn");

  return useMemo(
    () => (
      <Fragment>
        <Router forceRefresh>
          <Switch>
            <Route exact path="/">
              {login ? <Redirect to="/dashboard" /> : <Homepage />}
            </Route>
            <Route exact path="/contact">
              {login ? <Redirect to="/dashboard" /> : <Contact />}
            </Route>
            <Route path="/login">
              {login ? <Redirect to="/dashboard" /> : <Login />}
            </Route>
            <Route exact path="/dashboard">
              {login ? <Dashboard /> : <Redirect to="/login" />}
            </Route>
            <Route exact path="/dashboard/work">
              {login ? <DashWork /> : <Redirect to="/login" />}
            </Route>
          </Switch>
        </Router>
      </Fragment>
    ),
    [login]
  );
};

export default Index;
