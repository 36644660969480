import React, { useState, Fragment, memo } from "react";
import Slider from "react-slick";

const Project = memo((props) => {
  const [slider, setSlider] = useState();
  const nextSLick = () => {
    slider.slickNext();
  };
  const previousSlick = () => {
    slider.slickPrev();
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 577,
        settings: {
          infinite: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Fragment>
      <section id="click-section-2">
        <div className="container mb-5">
          <div className="row justify-content-center">
            <div className="col-xl-11">
              <div className="row">
                <div
                  className="col-lg-7 text-center text-lg-left my-auto"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  <h4 className="bold font__size--60 text__60-1024 text__60-mm text__gradient-purple mb-3">
                    Latest Projects
                  </h4>
                  <p className="lh-2  semi-bold font__size--14 text__14-1024 opacity__3">
                    With over 5 years of Industry experience, our design systems{" "}
                    <br className="d-none d-xl-block" /> are suitable for
                    anything your project needs
                  </p>
                </div>
                <div
                  className="col-md-5 d-none d-lg-block my-auto text-right"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="d-inline-block">
                    <div
                      onClick={nextSLick}
                      className="btn__project pointer d-flex justify-content-end align-items-center"
                    >
                      <h5 className="semi-bold font__size--16 mb-0 color__darkblue line__bottom-hover mr-4">
                        Click For Next Project
                      </h5>
                      <div className="icon">
                        <img
                          src="./../images/Arrow 1.png"
                          className="position-relative z-1"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Slider
                {...settings}
                ref={(slider) => setSlider(slider)}
                className="wrapper__project-grid mt__5"
              >
                <div className="grid">
                  <div
                    className="card__project four position-relative bluepurple text-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="600"
                  >
                    <img
                      src="./../images/saasaf.png"
                      className="content"
                      alt=""
                    />
                    <div className="icon-2">
                      <img src="./../images/Rocket.png" alt="" />
                    </div>

                    <h4 className="icon text-left font__size--60 black gradient">
                      Solstice
                    </h4>
                  </div>
                  <a
                    href="http://solstice-web.surge.sh"
                    target="_black"
                    className="bold font__size--24 text__24-1024 text__24-sm color__darkblue mt-4 title__text d-inline-block"
                  >
                    Solstice
                  </a>
                  <p className="semi-bold lh-2 color__gray-2">
                    Demo Example
                  </p>
                </div>
                <div className="grid">
                  <div
                    className="card__project five position-relative gray text-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="700"
                  >
                    <img
                      src="./../images/Group 18912.png"
                      className="content hover-center"
                      alt=""
                    />

                    <img
                      src="./../images/rthtgth4.png"
                      className="content-2"
                      alt=""
                    />
                    <div className="icon">
                      <img src="./../images/Mask Group (1) 1.png" alt="" />
                    </div>
                  </div>
                  <a
                    href="http://costa-brava-2.surge.sh"
                    target="_black"
                    className="bold font__size--24 text__24-1024 text__24-sm color__darkblue mt-4 title__text d-inline-block"
                  >
                    Costa Brava
                  </a>
                  <p className="semi-bold lh-2 color__gray-2">
                    Demo Example
                  </p>
                </div>
                <div className="grid">
                  <div
                    className="card__project six position-relative purple text-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="600"
                  >
                    <img
                      src="./../images/Group 18911.png"
                      className="content"
                      alt=""
                    />

                    <h4 className="icon text-left font__size--28 color__white ls__3 black">
                      LUNX
                    </h4>
                  </div>
                  <a
                    href="http://mvp-webiste-host.surge.sh"
                    target="_black"
                    className="bold font__size--24 text__24-1024 text__24-sm color__darkblue mt-4 title__text d-inline-block"
                  >
                    LUNX
                  </a>
                  <p className="semi-bold lh-2 color__gray-2">
                    Demo Example
                  </p>
                </div>
                <div className="grid">
                  <div
                    className="card__project one position-relative darkblue text-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="900"
                  >
                    <img
                      src="./../images/Mask Group (16).png"
                      className="content"
                      alt=""
                    />
                    <div className="icon">
                      <img src="./../images/Mask Group (15).png" alt="" />
                    </div>
                  </div>
                  <a
                    href="http://enversuite.surge.sh/"
                    target="_black"
                    className="bold font__size--24 text__24-1024 text__24-sm color__darkblue mt-4 title__text d-inline-block"
                  >
                    Enversuite
                  </a>
                  <p className="semi-bold lh-2 color__gray-2">
                    Demo Example
                  </p>
                </div>
                <div className="grid">
                  <div
                    className="card__project two gray position-relative softblue text-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="800"
                  >
                    <img
                      src="./../images/Group 18909.png"
                      className="content"
                      alt=""
                    />
                    <div className="icon">
                      <img src="./../images/sasasa.png" alt="" />
                    </div>
                  </div>
                  <a
                    href="http://fyt.surge.sh/"
                    target="_black"
                    className="bold font__size--24 text__24-1024 text__24-sm color__darkblue mt-4 title__text d-inline-block"
                  >
                    FYT App
                  </a>
                  <p className="semi-bold lh-2 color__gray-2">
                    UI Style Creation
                  </p>
                </div>
                <div className="grid">
                  <div
                    className="card__project three green position-relative text-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="700"
                  >
                    <img
                      src="./../images/Group 18910.png"
                      className="content"
                      alt=""
                    />
                    <h4 className="icon text-left font__size--45 bold color__white">
                      EzRent
                    </h4>
                  </div>
                  <a
                    href="http://ezrent-host.surge.sh/"
                    target="_black"
                    className="bold font__size--24 text__24-1024 text__24-sm color__darkblue mt-4 title__text d-inline-block"
                  >
                    Ez Rent
                  </a>
                  <p className="semi-bold lh-2 color__gray-2">
                    Demo Example
                  </p>
                </div>
                <div className="grid">
                  <div
                    className="card__project seven position-relative blue text-center"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    <img
                      src="./../images/sadsadsa.png"
                      className="content"
                      alt=""
                    />
                    <div className="icon">
                      <img src="./../images/novo logo.png" alt="" />
                    </div>
                  </div>
                  <a
                    href="http://novo-case-host.surge.sh"
                    target="_black"
                    className="bold font__size--24 text__24-1024 text__24-sm color__darkblue mt-4 title__text d-inline-block"
                  >
                    Novo
                  </a>
                  <p className="semi-bold lh-2 color__gray-2">
                    Demo Example
                  </p>
                </div>
              </Slider>
            </div>
          </div>

          <div
            className="text-center mt-5 d-block d-lg-none"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="1000"
          >
            <div
              onClick={nextSLick}
              className="d-inline-block semi-bold pointer color__darkblue border__bottom"
            >
              Click For Next Project
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
});

export default Project;
