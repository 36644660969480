import React, { Fragment } from "react";

const offer = (props) => {
  return (
    <Fragment>
      <div className="position-relative mt__10">
        <svg
          className="path-7 d-none d-md-block"
          width="227"
          height="196"
          viewBox="0 0 227 196"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.4"
            cx="144"
            cy="83"
            r="82.5"
            stroke="url(#paint0_linear)"
          />
          <circle
            opacity="0.4"
            cx="36"
            cy="160"
            r="35.5"
            stroke="url(#paint1_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="144"
              y1="0"
              x2="144"
              y2="166"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5A4EC5" />
              <stop offset="1" stop-color="#FD9EFA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="36"
              y1="124"
              x2="36"
              y2="196"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5A4EC5" />
              <stop offset="1" stop-color="#FD9EFA" />
            </linearGradient>
          </defs>
        </svg>

        <svg
          className="path-8 d-none d-md-block"
          width="296"
          height="224"
          viewBox="0 0 296 224"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.4"
            cx="112"
            cy="112"
            r="111.5"
            stroke="url(#paint0_linear)"
          />
          <circle
            opacity="0.4"
            cx="260"
            cy="36"
            r="35.5"
            stroke="url(#paint1_linear)"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="112"
              y1="0"
              x2="112"
              y2="224"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5A4EC5" />
              <stop offset="1" stop-color="#FD9EFA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="260"
              y1="0"
              x2="260"
              y2="72"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#5A4EC5" />
              <stop offset="1" stop-color="#FD9EFA" />
            </linearGradient>
          </defs>
        </svg>

        <section id="click-section-1">
          <div className="container">
            <div className="text-center">
              <h4
                className="bold font__size--60 text__60-1024 text__gradient-purple mb-3"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                What We Offer
              </h4>
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <p className="lh-2  semi-bold font__size--14 text__14-1024 opacity__3">
                  From user flow design to high fidelity prototyping to react
                  development, <br className="d-none d-xl-block" />
                  Our design team is your superpower
                </p>
              </div>
            </div>

            <div className="wrapper__grid-offer mt-5 flex-wrap d-flex justify-content-center align-items-center">
              <div className="grid">
                <div
                  className=" wrapper__btn-offer w-100 d-inline-block"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  <div className="d-flex justify-content-start align-items-center position-relative z-2">
                    <div className="icon flex-shrink-0">
                      <img src="./../images/Folder.png" alt="" />
                    </div>
                    <h5 className="ml-3 color__darkblue bold font__size--20 text__20-1024 text__20-sm text__20-xxs lh__5">
                      Lean Design Systems
                    </h5>
                  </div>
                </div>
              </div>

              <div className="grid">
                <div
                  className="wrapper__btn-offer w-100 d-inline-block"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  <div className="d-flex justify-content-start align-items-center position-relative z-2">
                    <div className="icon flex-shrink-0">
                      <img src="./../images/Image.png" alt="" />
                    </div>
                    <h5 className="ml-3 color__darkblue bold font__size--20 text__20-1024 text__20-sm text__20-xxs lh__5">
                      Development Ready Screens
                    </h5>
                  </div>
                </div>
              </div>

              <div className="grid">
                <div
                  className="wrapper__btn-offer w-100 d-inline-block"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <div className="d-flex justify-content-start align-items-center position-relative z-2">
                    <div className="icon flex-shrink-0">
                      <img src="./../images/Profile.png" alt="" />
                    </div>
                    <h5 className="ml-3 color__darkblue bold font__size--20 text__20-1024 text__20-sm text__20-xxs lh__5">
                      Intuitive UX
                    </h5>
                  </div>
                </div>
              </div>

              <div className="grid">
                <div
                  className="wrapper__btn-offer w-100 d-inline-block"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="600"
                >
                  <div className="d-flex justify-content-start align-items-center position-relative z-2">
                    <div className="icon flex-shrink-0">
                      <img src="./../images/Arrow - Right.png" alt="" />
                    </div>
                    <h5 className="ml-3 color__darkblue bold font__size--20 text__20-1024 text__20-sm text__20-xxs lh__5">
                      Unlimited Revisions
                    </h5>
                  </div>
                </div>
              </div>

              <div className="grid last">
                <div
                  className="wrapper__btn-offer w-100 d-inline-block"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="700"
                >
                  <div className="d-flex justify-content-start align-items-center position-relative z-2">
                    <div className="icon flex-shrink-0">
                      <img src="./../images/Edit Square.png" alt="" />
                    </div>
                    <h5 className="ml-3 color__darkblue bold font__size--20 text__20-1024 text__20-sm text__20-xxs lh__5">
                      Effecient Iteration
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default offer;
