import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const banner = (props) => {
  return (
    <Fragment>
      <section>
        <div className="container px-xl-5">
          <div className="wrapper__banner position-relative overflow-hidden">
            <img src="./../images/Group 3503.png" className="bg" alt="" />
            <img src="./../images/Ellipse 156.png" className="blur" alt="" />
            <img
              src="./../images/Group 18914.png"
              className="content d-none d-xl-block"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="400"
              alt=""
            />
            <div className="row">
              <div className="col-lg-6 text-center text-lg-left position-relative z-1">
                <h4
                  className="bold font__size--32 text__32-1024 color__white lh__5 m-0"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="100"
                >
                  Give us a call & turn your ideas into a future-facing product!
                </h4>
                <div
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <p className="semi-bold font__size--14 text__14-1024 lh-2 color__white opacity__5 my-3">
                    Contacts us for your free consultation & site/platform
                    audit. We are <br className="d-none d-xl-block" /> looking
                    forward to working with you!
                  </p>
                </div>
                <NavLink
                  to="/contact"
                  className="btn btn__pink color__white shadow extra-bold font__size--14 text__14-1024 btn__contact d-inline-block"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  data-aos-delay="300"
                >
                  Contact Us
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default banner;
