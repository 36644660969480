import React, {
  Component,
  Fragment,
  useState,
  useEffect,
  useMemo,
} from "react";
import { Helmet } from "react-helmet";
import { $ } from "react-jquery-plugin";
// import $ from "jquery";
import { TweenMax, TimelineMax } from "gsap";
import Scrollspy from "react-scrollspy";
import { NavLink } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

import Offer from "./offer";
import Project from "./project";
import Howto from "./howto";
import Banner from "./banner";
import Footer from "./footer";

const Index = (props) => {
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (menu) {
      var speed = 0.5;
      var speed2 = 0.5;

      $(".list__menu-data li").each(function (i, obj) {
        TweenMax.from(obj, (speed += 0.3), {
          top: "initial",
          left: "initial",
          bottom: "initial",
          right: "initial",
          opacity: 0,
          xPercent: 0,
          yPercent: 250,
          onComplete: function () {},
        });
      });

      $(".animateBottom").each(function (i, obj) {
        TweenMax.from(obj, (speed2 += 0.3), {
          top: "initial",
          left: "initial",
          bottom: "initial",
          right: "initial",
          opacity: 1,
          xPercent: -150,
          yPercent: 0,
          onComplete: function () {},
        });
      });
    }

    AOS.init({
      once: true,
    });

    $("a[href^='#click-']").on("click", function (e) {
      e.preventDefault();
      $(".responsive__fixed").removeClass("show");
      $("html, body").animate(
        {
          scrollTop: $($(this).attr("href")).offset().top,
        },
        1000
      );
    });

    $(function () {
      var menu = $(".wrapper__nav-fixed"),
        pos = menu.offset();

      $(window).scroll(function () {
        if ($(this).scrollTop() > menu.height()) {
          $(".wrapper__nav-fixed").addClass("active");
        } else if ($(this).scrollTop() <= menu.height()) {
          $(".wrapper__nav-fixed").removeClass("active");
        }
      });
    });

    var windowWidth = $(window).width();

    var mouse = $(".mouse-move");
    var block = $(".wrapper__block");
    var tl = new TimelineMax({ repeat: 0, delay: 1.5 });

    window.onload = function (e) {
      if (windowWidth > 1440) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 967,
            yPercent: -303,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 1182,
            yPercent: -203,
          }),
          TweenMax.to(block, 1.5, {
            width: "276px",
            height: "96px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 983,
            yPercent: -197,
          }),
        ]);
      } else if (windowWidth == 1440) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 377,
            yPercent: -227,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 595,
            yPercent: -135,
          }),
          TweenMax.to(block, 1.5, {
            width: "276px",
            height: "96px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 404,
            yPercent: -121,
          }),
        ]);
      } else if (windowWidth >= 1200) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 333,
            yPercent: -226,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 550,
            yPercent: -130,
          }),
          TweenMax.to(block, 1.5, {
            width: "276px",
            height: "96px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 336,
            yPercent: -109,
          }),
        ]);
      } else if (windowWidth >= 1000) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 214,
            yPercent: -179,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 397,
            yPercent: -106,
          }),
          TweenMax.to(block, 1.5, {
            width: "235px",
            height: "75px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 222,
            yPercent: -83,
          }),
        ]);
      } else if (windowWidth >= 850) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 123,
            yPercent: -156,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 302,
            yPercent: -81,
          }),
          TweenMax.to(block, 1.5, {
            width: "230px",
            height: "75px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 123,
            yPercent: -81,
          }),
        ]);
      }else if (windowWidth >= 750) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 115,
            yPercent: -200,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 338,
            yPercent: -109,
          }),
          TweenMax.to(block, 1.5, {
            width: "230px",
            height: "75px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 140,
            yPercent: -95,
          }),
        ]);
      } else if (windowWidth >= 560) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 55,
            yPercent: -255,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 278,
            yPercent: -135,
          }),
          TweenMax.to(block, 1.5, {
            width: "230px",
            height: "75px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 171,
            yPercent: -231,
          }),
        ]);
      } else if (windowWidth >= 500) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 169,
            yPercent: -238,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 417,
            yPercent: -139,
          }),
          TweenMax.to(block, 1.5, {
            width: "166px",
            height: "52px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 128,
            yPercent: 60,
          }),
        ]);
      } else if (windowWidth >= 460) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 138,
            yPercent: -230,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 385,
            yPercent: -136,
          }),
          TweenMax.to(block, 1.5, {
            width: "166px",
            height: "52px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 126,
            yPercent: -366,
          }),
        ]);
      } else if (windowWidth >= 400) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 108,
            yPercent: -257,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 355,
            yPercent: -163,
          }),
          TweenMax.to(block, 1.5, {
            width: "166px",
            height: "52px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 142,
            yPercent: -398,
          }),
        ]);
      } else if (windowWidth >= 360) {
        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 63,
            yPercent: -264,
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 284,
            yPercent: -173,
          }),
          TweenMax.to(block, 1.5, {
            width: "146px",
            height: "52px",
          }),
        ]);

        tl.add([
          TweenMax.to(mouse, 1.5, {
            xPercent: 142,
            yPercent: -523,
          }),
        ]);
      }
    };
  }, [menu]);

  return useMemo(
    () => (
      <Fragment>
        <Helmet>
          <title>Home</title>
        </Helmet>

        <div className="overflow-hidden">
          <div className={"wrapper__navbar-menu " + (menu ? "active" : "")}>
            <div className="position-relative w-100 h-100">
              <div className="list__menu bg__white">
                <div className="text-right mb-5">
                  <div
                    className="icon__close ml-auto pointer"
                    onClick={() => setMenu(!menu)}
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.8906 7.40137L13.9405 12.3504L8.99146 7.40137L7.3418 9.05103L12.2908 14L7.3418 18.949L8.99146 20.5987L13.9405 15.6497L18.8906 20.5987L20.5403 18.949L15.5913 14L20.5403 9.05103L18.8906 7.40137Z"
                        fill="#1A355E"
                      />
                    </svg>
                  </div>
                </div>

                <Scrollspy
                  items={[
                    "click-section-1",
                    "click-section-2",
                    "click-section-3",
                  ]}
                  currentClassName="is-current"
                  className="list__menu-data"
                >
                  <li>
                    <a
                      href="#click-section-1"
                      onClick={() => setMenu(!menu)}
                      className="font__size--36 text__36-1024 bold color__darkblue animateMenu"
                    >
                      SERVICE
                    </a>
                  </li>
                  <li>
                    <a
                      href="#click-section-2"
                      onClick={() => setMenu(!menu)}
                      className="font__size--36 text__36-1024 bold color__darkblue animateMenu"
                    >
                      PROJECT
                    </a>
                  </li>
                  <li>
                    <a
                      href="#click-section-3"
                      onClick={() => setMenu(!menu)}
                      className="font__size--36 text__36-1024 bold color__darkblue animateMenu"
                    >
                      HOW IT WORKS
                    </a>
                  </li>
                </Scrollspy>

                <hr className="hr__nav-menu mb-4" />
                <div className="row">
                  <div className="col-md-6 pb-4 pb-md-0 my-auto">
                    <div className="list__sosmed d-flex justify-content-md-start justify-content-center align-items-center">
                      <a
                        href="https://dribbble.com/Enver-studio"
                        target="_blank"
                      >
                        <img
                          src="./../images/Frame (3).png"
                          className="animateBottom"
                          alt=""
                        />
                      </a>
                      <a
                        href="https://instagram.com/enverstudio?utm_medium=copy_link"
                        target="_blank"
                      >
                        <img
                          src="./../images/Frame (2).png"
                          className="animateBottom"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 my-auto text-center text-md-right">
                    <a
                      href="mailto:hi@enverstudio.com"
                      className="semi-bold font__size--18 color__darkblue d-inline-block animateBottom"
                    >
                      hi@enverstudio.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="position-relative wrapper__head">
            <img src="./../images/Group 3492.png" className="bg" alt="" />

            <img
              src="./../images/Mask Group (13) (1).png"
              className="path-1 d-none d-lg-block animate__float1"
              alt=""
            />
            <img
              src="./../images/Mask Group (14) (1).png"
              className="path-2 d-none d-lg-block animate__float2"
              alt=""
            />

            <img
              src="./../images/Group 3559.png"
              className="path-3 d-none d-lg-block"
              alt=""
            />

            <div className="path-4 d-flex">
              <img src="./../images/sketch-2 1.png" alt="" />
            </div>

            <div className="path-5 d-flex">
              <img src="./../images/Figma-logo 1.png" alt="" />
            </div>

            <div className="path-6 d-flex">
              <img src="./../images/dsffsd.png" alt="" />
            </div>

            <img
              src="./../images/Group 3446.png"
              className="mouse-move d-xl-block"
              alt=""
            />

            <section className="section__head pt-0">
              <div className="wrapper__nav-fixed">
                <div className="container">
                  <div className="wrapper__navbar position-relative z-2 d-flex justify-content-between align-items-center">
                    <NavLink to="/">
                      <img src="./../images/Group 66.svg" alt="" />
                    </NavLink>
                    <img
                      src="./../images/Frame (1).png"
                      className="pointer"
                      onClick={() => setMenu(!menu)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="container position-relative z-1"
                style={{ paddingTop: "173px" }}
              >
                <div className="text-center position-relative my__2">
                  <img
                    src="./../images/Group 3559 (1).png"
                    className="wrapper__block"
                    alt=""
                  />
                  <h1 className="bold font__size--60 text__60-1024 text__60-mm text__60-xxs color__white mb-4">
                    Custom UI / UX for your <br className="d-none d-lg-block" />{" "}
                    Software Product{" "}
                  </h1>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <p className="lh-2  semi-bold font__size--14 text__14-1024 color__white opacity__5 mb-4">
                      Your design will shine alongside industry-leading products
                    </p>
                  </div>

                  <div className="d-flex justify-content-center align-items-center wrapper__head-button">
                    <NavLink
                      to="/contact"
                      className="btn btn__head btn__pink shadow extra-bold font__size--14 text__14-1024 color__white mr-4"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                      data-aos-delay="200"
                    >
                      Contact Us
                    </NavLink>
                  </div>
                </div>
              </div>
            </section>

            <img src="./../images/Subtract.png" className="w-100" alt="" />
          </div>

          <Offer />

          <Project />

          <Howto />

          <Banner />

          <Footer />
        </div>
      </Fragment>
    ),
    [menu]
  );
};

export default Index;
