import React, { Component, Fragment } from "react";
// import { $ } from "react-jquery-plugin";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { NavLink } from "react-router-dom";

class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      menuLeft: false,
      menuRight: false,
      redirect: false,
      apply: [],
      loading: true,
    };
  }
  componentDidMount() {
    AOS.init({
      once: true,
    });

    this.getDataApi();

    $(document).ready(function () {
      $("#tabelContact").DataTable({
        responsive: true,
      });
    });
  }

  componentDidUpdate() {
    // $(document).ready(function () {
    //   $("#tabelContact").DataTable({
    //     responsive: true,
    //   });
    // });
  }

  getDataApi = () => {
    axios.get(process.env.REACT_APP_API + "apply/get").then((res) => {
      this.setState({
        apply: res.data.data,
        loading: false,
      });
    });
  };

  dropdownClick = () => {
    this.setState({
      dropdown: !this.state.dropdown,
    });
  };

  menuClickLeft = () => {
    this.setState({
      menuLeft: !this.state.menuLeft,
      menuRight: this.state.menuRight ? false : false,
    });
  };

  menuClickright = () => {
    this.setState({
      menuRight: !this.state.menuRight,
      menuLeft: this.state.menuLeft ? false : false,
    });
  };

  clickLogout = () => {
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("userData");

    this.setState({
      redirect: true,
    });
  };

  render() {
    const login = localStorage.getItem("loggedIn");
    if (!login) {
      return <Redirect to="/login" />;
    }
    return (
      <Fragment>
        <div className="dashboard">
          <div className="wrapper__dashbaord">
            <div
              className={"side-left " + (this.state.menuLeft ? "active" : "")}
            >
              <div className="wrapper__head">
                <div className="head mb-5">
                  <h5 className="extra-bold font__size--16 color__blue-2 title mb-4 position-relative">
                    SAQU
                    <div
                      className="closeMenu d-xl-none left"
                      onClick={this.menuClickLeft.bind(this)}
                    >
                      <img
                        src="./../images/dashboard/x-regular-24.png"
                        alt=""
                      />
                    </div>
                  </h5>
                  <ul className="list__left-menu">
                    <li>
                      <NavLink
                        to="/dashboard"
                        exact
                        activeClassName="active"
                        className="d-flex justify-content-start align-items-center position-relative"
                      >
                        <img
                          src="./../images/dashboard/Home.png"
                          className="mr-3 object-fit-content"
                          alt=""
                        />
                        <span className="bold font__size--12 color__black">
                          Contact
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/dashboard/work"
                        activeClassName="active"
                        className="d-flex justify-content-start align-items-center position-relative"
                      >
                        <img
                          src="./../images/dashboard/Home.png"
                          className="mr-3 object-fit-content"
                          alt=""
                        />
                        <span className="bold font__size--12 color__black">
                          Works
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="footer">
                  <hr className="hr__side-left mt-0" />
                  <div className="menu">
                    <ul className="list__left-footer-menu">
                      <li>
                        <a
                          href="#!"
                          onClick={() => this.clickLogout()}
                          className="d-flex justify-content-start align-items-center position-relative "
                        >
                          <img
                            src="./../images/dashboard/Download.png"
                            className="mr-3 object-fit-content"
                            alt=""
                          />
                          <span className="bold font__size--12 color__black">
                            Log Out
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
              <section className="section__content pt-2">
                <div className="container">
                  {/* onClick={this.dropdownClick.bind(this)} */}
                  <div className="wrapper__menu-mobile d-flex justify-content-between align-items-center d-xl-none mb-4">
                    <div
                      className="menuIcon"
                      onClick={this.menuClickLeft.bind(this)}
                    >
                      <img
                        src="./../images/dashboard/menu-alt-left-regular-24.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    {this.state.loading ? (
                      <div className="wrapper__laoding-content">
                        <img
                          src={
                            process.env.REACT_APP_URL +
                            "/images/output-onlinegiftools.gif"
                          }
                          alt=""
                        />
                      </div>
                    ) : (
                      <div className="wrapper__statistic color__black">
                        <div class="table-responsive">
                          <table
                            id="tabelContact"
                            ref={(el) => (this.el = el)}
                            class="display table"
                          >
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>About</th>
                                <th>Need</th>
                                <th>File</th>
                                <th>budget</th>
                                <th>NDA</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.apply.map((item) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>{item.email}</td>
                                    <td>{item.about}</td>
                                    <td>{item.need}</td>
                                    <td>
                                      <a target="_blank" href={item.file}>
                                        Get FIle
                                      </a>
                                    </td>
                                    <td>{item.budget}</td>
                                    <td>{item.NDA ? "Yes" : "No"}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Work;
