import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { $ } from "react-jquery-plugin";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Redirect, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: false,
      image: "./../images/moto/dsadasdasd.png",
      redirect: false,
      login: {
        email: "",
        password: "",
      },
    };
  }
  componentDidMount() {
    AOS.init({
      once: true,
    });
  }

  showPassword = () => {
    this.setState({
      password: !this.state.password,
    });

    if (this.state.password) {
      this.setState({
        image: "./../images/moto/dsadasdasd.png",
      });
    } else {
      this.setState({
        image: "./../images/moto/sadsad.png",
      });
    }
  };

  onChangeInput = (e) => {
    let loginInput = { ...this.state.login };
    loginInput[e.target.name] = e.target.value;

    this.setState({
      login: loginInput,
    });
  };

  btnLogin = () => {
    axios.post(process.env.REACT_APP_API + `login`, this.state.login).then(
      (res) => {
        console.log(res);
        if (res.data.success == "success") {
          localStorage.setItem("loggedIn", true);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          toast.success("you have successfully logged in", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
          });

          this.setState({
            redirect: true,
          });
        } else {
          $.each(res.data.errors, function (index, item) {
            toast.error(item[0], {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          });

          if (res.data.message != "validation_error") {
            toast.error(res.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: 0,
            });
          }
        }
      },
      (err) => {
        console.log("error ", err);
      }
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <Fragment>
        <Helmet>
          <title>WowSee - Login</title>
        </Helmet>
        <ToastContainer />
        <section className="min-height-100 p-0 w-100">
          <div className="row  justify-content-center h-100 w-100 m-0">
            <div className="col-lg-6 px-0">
              <div className="h-100 py-5 d-flex justify-content-center align-items-center">
                <div className="row w-100 justify-content-center">
                  <div className="col-md-11">
                    <div className="container px-0">
                      <div className="text-center">
                        <div className="d-block mb-5">
                          <NavLink
                            to="/"
                            className="wrapper__title-log position-relative d-inline-block  bold font-italic color__tosca"
                          >
                            <img
                              src="./../images/moto/Full Logo-Transparent-Dark.png"
                              className="images__logo-new"
                              alt=""
                            />
                          </NavLink>
                        </div>
                        <h3 className=" semi-bold font__size--50 text__50-1024 text__50-sm text__50-xxs color__blue-3 mb-4">
                          Sign In to your account
                        </h3>
                        <h5 className="medium lh-2 font__size--20 text__20-1024 text__20-sm color__blue-3 opacity__4 mb-4">
                          Please fill in the data below to enter your account
                        </h5>
                      </div>

                      <div className="mb-4">
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="bold font__size--16 text__16-1024 color__blue-3 opacity__6"
                          >
                            Email
                          </label>
                          <div className="wrapper__input-log bg__gray-8 d-flex justify-content-start align-items-center">
                            <img
                              src="./../images/moto/asdasdsa.png"
                              className="mr-2"
                              alt=""
                            />
                            <input
                              type="email"
                              onChange={this.onChangeInput}
                              name="email"
                              value={this.state.login.email}
                              className="form-control input font__size--16 text__16-1024 color__blue-3 opacity__6 bold"
                              placeholder="Insert your email here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" mb-4">
                        <div className="form-group">
                          <label
                            htmlFor=""
                            className="bold font__size--16 text__16-1024 color__blue-3 opacity__6"
                          >
                            Password
                          </label>
                          <div className="wrapper__input-log bg__gray-8 d-flex justify-content-start align-items-center">
                            <img
                              src="./../images/moto/sadsad.png"
                              className="mr-2"
                              alt=""
                            />
                            <input
                              onChange={this.onChangeInput}
                              type={this.state.password ? "text" : "password"}
                              autoComplete="off"
                              name="password"
                              className="form-control input font__size--16 text__16-1024 color__blue-3 opacity__6 bold"
                              placeholder="Insert your password here"
                            />
                            <img
                              name="password"
                              onChange={this.onChangeInput}
                              onClick={() => this.showPassword()}
                              src={this.state.image}
                              className="ml-3 pointer"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => this.btnLogin()}
                        className=" font__size--24 text__24-1024 meidum color__white btn btn__blue w-100 shadow btn__login"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}
