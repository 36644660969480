import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

const footer = (props) => {
  return (
    <Fragment>
      <section className={"pb-4 " + props.bgcolor}>
        <div className="container px-md-5">
          <div className="row">
            <div className="col-md-4 text-center text-md-left">
              <NavLink to="/" className="d-inline-block mb-3">
                <img src="./../images/Group 3521.png" alt="" />
              </NavLink>
              <p className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 lh-5 mb-5">
                We are a creative agency that will help you to meet your UIUX
                Design needs for your digital products
              </p>
              <ul className="list__data-footer medium font__size--14 text__14-1024 color__darkblue">
                <li>629 555-0129</li>
                <li>San Diego, California - USA</li>
                <li>hi@enverstudio.com</li>
              </ul>
            </div>
            <div className="col-md-2 d-none d-md-block offset-2">
              <h5 className="extra-bold font__size--14 text__14-1024 color__darkblue mb-3">
                MENU
              </h5>
              <ul className="list__menu-footer">
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    SERVICE
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    PROJECT
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    HOW IT WORKS
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    PRICING
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <h5 className="extra-bold font__size--14 text__14-1024 color__darkblue mb-3">
                FIND US
              </h5>
              <ul className="list__menu-footer">
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    INSTAGRAM
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    DRIBBBLE
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    BEHANCE
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    className="medium font__size--14 text__14-1024 color__gray-1 opacity__5 hover"
                  >
                    LINKEDIN
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <hr className="hr__footer my-4" />

          <p className="m-0 lh-2 text-center bold font__size--14 text__14-1024 color__gray-1 opacity__6">
            © Copyright Enver Studio 2020 - All right Reserved
          </p>
        </div>
      </section>
    </Fragment>
  );
};

export default footer;
